import { createApp } from 'vue'
import App from './App.vue'
import "semantic-ui-css/semantic.min.css";
import axios from 'axios'
import VueAxios from 'vue-axios'


 import VueHighcharts from 'vue3-highcharts';

const app = createApp(App)
app.use(VueHighcharts)
app.use(VueAxios, axios)
app.mount('#app')