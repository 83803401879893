import { WebMarketPrice } from "@/model/WebMarketPrice";
import axios, { AxiosResponse } from "axios";
import { Trigger } from "src/model/Trigger";

export default class API {

    public static INSTANCE = new API();
    //Produktiv
    private host = window.location.host;
    //zum testen aus vue ui
    //private host = "localhost:80";

    // TRIGGER ########################################

    public getApiTrigger(): Promise<AxiosResponse<Trigger[]>> {
        const result = axios.get<Trigger[]>("http://" + this.host + "/api/trigger");
        result.catch((e) => console.warn(e));
        return result;
    }

    public delTrigger(id: String): Promise<any> {
        const result = axios.get<any>("http://" + this.host + "/api/trigger/del/" + id);
        result.catch((e) => console.warn(e));
        return result;
    }

    public addTrigger(coin: String, currency: String, price: String, type: String): Promise<any> {
        const result = axios.get<any>("http://" + this.host + "/api/trigger/add/" + type + "/" + coin + "/" + currency + "/" + price);
        result.catch((e) => console.warn(e));
        return result;
    }

    // MarketPriceApiController ########################################

    ///api/marketprices

    public getWebMarketPrices(): Promise<AxiosResponse<WebMarketPrice[]>> {
        const result = axios.get<WebMarketPrice[]>("http://" + this.host + "/api/marketprices");
        result.catch((e) => console.warn(e));
        return result;
    }

    // Chart
    /**
     * Lädt die Chartdaten für einen vorgegeben Zeitraum.
     * 
     * @param symbol XRPBTC
     * @returns array mit arrays aus zeitpunkt und wert
     */
    public getChartData(symbol: string): Promise<AxiosResponse<number[][]>> {
        const result = axios.get("http://" + this.host + "/api/chart/ticker/" + symbol + "/bestBidPrice");
        result.catch((e) => console.warn(e));
        return result;
    }


    // /api/chart/latestticker/XRPBTC
    public getLatestChartData(symbol: string): Promise<AxiosResponse<number[][]>> {
        const result = axios.get("http://" + this.host + "/api/chart/latestticker/" + symbol);
        result.catch((e) => console.warn(e));
        return result;
    }

}